import { FC } from "react";
import { Flex, Spinner } from "theme-ui";

export const Loading: FC = () => {
  return (
    <Flex sx={{ justifyContent: "center", p: 2 }}>
      <Spinner />
    </Flex>
  );
};
