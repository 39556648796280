import { useEffect, useState } from "react";
import { Container } from "theme-ui";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Loading } from "./components/loading";
import { Survey } from "./components/survey";

declare global {
  interface Window {
    Shopify: {
      checkout: {
        order_id: string;
      };
    };
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  const [orderId, setOrderId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let orderIdFromQuery = new URLSearchParams(window.location.search).get(
    "orderId"
  );

  useEffect(() => {
    setIsLoading(false);
    if (!window.Shopify) {
      orderIdFromQuery && setOrderId(orderIdFromQuery);
      return;
    }
    setOrderId(orderIdFromQuery || window.Shopify.checkout.order_id);
  }, [orderIdFromQuery]);

  if (isLoading) {
    return <Loading />;
  }

  if (!orderId) {
    return null;
  }

  return (
    <Container my={3}>
      <Survey orderId={orderId} />
    </Container>
  );
}

export default App;
