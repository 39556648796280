import { FC } from "react";
import { useEffect } from "react";
import { Box, SxProp } from "theme-ui";

export interface EmbedSectionProps {
  scriptUrl: string;
  embedId: string;
  sx?: SxProp;
  beforeLoad?: () => void;
  cleanup?: () => void;
}

export const EmbedSection: FC<EmbedSectionProps> = ({
  embedId,
  scriptUrl,
  cleanup,
  beforeLoad,
  sx,
}) => {
  useEffect(() => {
    beforeLoad && beforeLoad();
    const scriptId = `script-${embedId}`;
    const scriptTag = document.createElement("script");
    scriptTag.src = scriptUrl;
    scriptTag.id = scriptId;
    document.body.appendChild(scriptTag);
    return () => {
      cleanup && cleanup();
      scriptTag.remove();
    };
  }, [scriptUrl, beforeLoad, cleanup, embedId]);

  return <Box id={embedId} />;
};
