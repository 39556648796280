import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "theme-ui";
import { theme } from "./theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const survey = document.createElement("div");
survey.setAttribute("id", "heights-survey");
document.getElementById("checkout")?.prepend(survey);
document
  .getElementsByClassName("thank-you__additional-content")[0]
  ?.prepend(survey);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("heights-survey")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
