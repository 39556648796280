import { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Button, Label, Radio, Spinner, Text, Textarea } from "theme-ui";
import {
  ChoiceModel,
  ResponseModel,
  SurveyChoice,
  SurveyQuestion,
} from "../models/survey";
import { getSurvey, putResponse, storeChoice } from "../utils/api";
import { EmbedSection } from "./embed";
import { Loading } from "./loading";

declare global {
  interface Window {
    mmSurveyData: {
      firstname: string;
      surname: string;
      email: string;
      address_country: string;
      custom_field: string;
    };
  }
}

interface SurveyProps {
  orderId: string;
}

export const Survey: FC<SurveyProps> = ({ orderId }) => {
  const [surveyQuestion, setSurveyQuestion] = useState<SurveyQuestion>();
  const [response, setResponse] = useState<ResponseModel>();
  const [surveyId, setSurveyId] = useState<string>();
  const [choices, setChoices] = useState<SurveyChoice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSyncing, setIsSyncing] = useState<boolean>();
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [showAdditional, setShowAdditional] = useState<boolean>();
  const [additionalText, setAdditionalText] = useState<string>();
  const [isComplete, setIsComplete] = useState<boolean>();

  useEffect(() => {
    const fetchSurvey = () =>
      getSurvey().then((data) => {
        const question = data.questions[0];
        const topLevelChoices = question.choices.filter(
          (choice) => !choice.parentId
        );
        setSurveyId(data.surveyId);
        setSurveyQuestion(question);
        setChoices(topLevelChoices);
      });

    const fetchResponse = () =>
      putResponse(orderId).then((data) => {
        setResponse(data);
        if (data.answers.length) {
          // setIsHidden(true);
          setIsComplete(true);
        }
      });
    Promise.all([fetchSurvey(), fetchResponse()]).finally(() => {
      setIsLoading(false);
    });
  }, [orderId]);

  useEffect(() => {
    if (!selectedAnswer || isSyncing) return;
    const nextChoices = surveyQuestion?.choices.filter(
      (choice) => choice.parentId === selectedAnswer
    );
    if (!nextChoices?.length) {
      return handleOther();
    }

    setChoices(nextChoices);
  }, [selectedAnswer, surveyQuestion, isSyncing]);

  if (isLoading) {
    return <Loading />;
  }

  if (!surveyQuestion || !response || !surveyId) {
    return null;
  }

  const handleOther = () => {
    setShowAdditional(true);
  };

  const onAdditionalChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setAdditionalText(value);
  };

  const handleAdditional = () => {
    const choice: ChoiceModel = {
      parentId: selectedAnswer,
      answerText: additionalText || "",
      responseId: response.id,
      surveyId,
      questionId: surveyQuestion.id,
    };
    setIsSyncing(true);
    storeChoice(response.id, choice).finally(() => {
      setIsComplete(true);
      setIsSyncing(false);
    });
  };

  const handleChoice = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const choiceData = choices.find((x) => x.id === value);
    const choice: ChoiceModel = {
      answerId: choiceData?.id || value,
      parentId: choiceData?.parentId,
      answerText: choiceData?.text || "",
      responseId: response.id,
      surveyId,
      questionId: surveyQuestion.id,
    };
    setSelectedAnswer(value);
    setIsSyncing(true);
    storeChoice(response.id, choice).finally(() => {
      setIsSyncing(false);
    });
  };

  if (isComplete) {
    let scriptUrl: string = "";
    if (window.mmSurveyData) {
      const { firstname, surname, email, address_country, custom_field } =
        window.mmSurveyData;

      scriptUrl = `https://tag.mention-me.com/api/v2/referreroffer/mm056f96f0?firstname=${firstname}&surname=${surname}&email=${email}&situation=postsurvey&locale=en_GB&address_country=${address_country}}&custom_field=${custom_field}`;
    }
    return (
      <Box
        marginY={4}
        padding={4}
        sx={{
          border: "1px solid",
          borderColor: "secondary",
          borderRadius: 5,
        }}
      >
        {scriptUrl && (
          <EmbedSection scriptUrl={scriptUrl} embedId="mmWrapper" />
        )}
        <Text sx={{ fontSize: 4 }}>Thank you for your feedback!</Text>
      </Box>
    );
  }

  if (showAdditional) {
    return (
      <Box
        padding={4}
        sx={{
          border: "1px solid",
          borderColor: "secondary",
          borderRadius: 5,
        }}
      >
        <Text sx={{ fontSize: 4 }} as="div">
          Additional comments
        </Text>
        <Text>The more detail the better - thank you!</Text>
        <Textarea
          rows={4}
          my={2}
          value={additionalText}
          onChange={onAdditionalChange}
        ></Textarea>
        <Button onClick={handleAdditional}>
          {isSyncing ? (
            <>
              <Spinner size={14} color="muted" /> Submitting...
            </>
          ) : (
            <>Submit</>
          )}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      padding={4}
      sx={{
        border: "1px solid",
        borderColor: "secondary",
        borderRadius: 5,
      }}
    >
      <Text sx={{ fontSize: 4 }}>{surveyQuestion.text}</Text>
      <Box mt={2}>
        {choices.map((choice) => (
          <Label key={choice.id} py={2} sx={{ alignItems: "center" }}>
            {isSyncing && selectedAnswer === choice.id ? (
              <Spinner size={24} mr={2} />
            ) : (
              <Radio
                name="choice"
                value={choice.id}
                onChange={handleChoice}
                disabled={isSyncing}
              />
            )}{" "}
            <Text>{choice.text}</Text>
          </Label>
        ))}
        <Label key="other-option" py={2}>
          <Radio
            name="choice"
            value="other"
            onChange={handleOther}
            disabled={isSyncing}
          />
          <Text>Other</Text>
        </Label>
      </Box>
    </Box>
  );
};
