import axios from "axios";
import { ChoiceModel, ResponseModel, SurveyModel } from "../models/survey";

const surveyUrl = `${process.env.REACT_APP_SURVEY_API}/${process.env.REACT_APP_SURVEY_ID}`;

export const getSurvey = async () => {
  const response = await axios.get<SurveyModel>(surveyUrl);

  return response.data;
};

export const putResponse = async (orderId: string) => {
  const response = await axios.put<ResponseModel>(`${surveyUrl}/${orderId}`);

  return response.data;
};

export const storeChoice = async (responseId: string, choice: ChoiceModel) => {
  const response = await axios.post(
    `${surveyUrl}/responses/${responseId}`,
    choice
  );

  return response.data;
};
